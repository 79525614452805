<template>
  <div class="main">
    <div class="m-box">
      <!-- 轮播图 -->
      <div
        id="top-wrap"
        class="m-swiper"
        style="border-bottom: 3px solid white"
      >
        <div class="layui-carousel" id="test1">
          <div carousel-item>
            <div
              :class="'m-swiper-item ' + item.back"
              v-for="(item, index) in swipers"
              v-bind:key="index"
            >
              <div class="swiper_page1">
                <div class="swiper_title1">
                  {{ item.title }}
                </div>
                <p class="swiper_p1">{{ item.desc }}</p>
                <a :href="item.href" class="swiper_btn">了解详情</a>
              </div>
            </div>

            <div class="m-swiper-item">
              <div class="box-wrap wrap-back-4">
                <div class="box">
                  <div class="box-header" style="margin-top: 84px">
                    <h2 class="box-title" style="color: white">
                      厦门神仙网络科技有限公司
                    </h2>

                    <div class="box-sub">
                      <span
                        class="box-sub_desc"
                        style="
                          color: #409eff;
                          font-weight: 900;
                          font-size: 20px;
                        "
                        >腾讯云（厦门）授权服务中心</span
                      >
                    </div>
                  </div>
                  <div class="box-centent" style="margin-bottom: 15px">
                    <div class="jieshao-box">
                      <ul class="jieshao-list">
                        <li class="jieshao-item">
                          <div class="hangkuai jieshao-item-icon">
                            <!-- <img
                      src="https://sams-home-online-1302115363.file.myqcloud.com/static/brandLogo1.1bc12572.png"
                      alt=""
                    /> -->

                            <svg
                              t="1646298262008"
                              class="icon svg_temp"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="11544"
                              width="200"
                              height="200"
                            >
                              <path
                                d="M576 904.533333c-17.066667 0-29.866667-12.8-34.133333-25.6-4.266667-17.066667 8.533333-34.133333 25.6-38.4 174.933333-29.866667 302.933333-179.2 302.933333-354.133333 0-200.533333-162.133333-362.666667-362.666667-362.666667s-362.666667 162.133333-362.666666 362.666667c0 85.333333 29.866667 166.4 85.333333 230.4 12.8 12.8 8.533333 34.133333-4.266667 46.933333-12.8 12.8-34.133333 8.533333-46.933333-4.266666C119.466667 678.4 85.333333 584.533333 85.333333 482.133333c0-234.666667 192-426.666667 426.666667-426.666666s426.666667 192 426.666667 426.666666c0 209.066667-149.333333 388.266667-358.4 422.4h-4.266667z"
                                p-id="11545"
                                data-spm-anchor-id="a313x.7781069.0.i49"
                                class="selected"
                                fill="#1296db"
                              ></path>
                              <path
                                d="M315.733333 482.133333m-51.2 0a51.2 51.2 0 1 0 102.4 0 51.2 51.2 0 1 0-102.4 0Z"
                                p-id="11546"
                                data-spm-anchor-id="a313x.7781069.0.i52"
                                class="selected"
                                fill="#1296db"
                              ></path>
                              <path
                                d="M512 482.133333m-51.2 0a51.2 51.2 0 1 0 102.4 0 51.2 51.2 0 1 0-102.4 0Z"
                                p-id="11547"
                                data-spm-anchor-id="a313x.7781069.0.i50"
                                class="selected"
                                fill="#1296db"
                              ></path>
                              <path
                                d="M708.266667 482.133333m-51.2 0a51.2 51.2 0 1 0 102.4 0 51.2 51.2 0 1 0-102.4 0Z"
                                p-id="11548"
                                data-spm-anchor-id="a313x.7781069.0.i51"
                                class="selected"
                                fill="#1296db"
                              ></path>
                              <path
                                d="M503.466667 960c-46.933333 0-81.066667-38.4-81.066667-81.066667 0-46.933333 38.4-81.066667 81.066667-81.066666s81.066667 38.4 81.066666 81.066666c4.266667 42.666667-34.133333 81.066667-81.066666 81.066667z m0-115.2c-17.066667 0-29.866667 12.8-29.866667 29.866667s12.8 29.866667 29.866667 29.866666c17.066667 0 29.866667-12.8 29.866666-29.866666s-8.533333-29.866667-29.866666-29.866667z"
                                p-id="11549"
                                data-spm-anchor-id="a313x.7781069.0.i53"
                                class="selected"
                                fill="#1296db"
                              ></path>
                            </svg>
                          </div>
                          <div class="jieshao-item-text">
                            <p>产品咨询</p>
                          </div>
                        </li>
                        <li class="jieshao-item">
                          <div class="hangkuai jieshao-item-icon">
                            <svg
                              t="1646276155319"
                              class="icon svg_temp"
                              viewBox="0 0 1024 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="6519"
                              data-spm-anchor-id="a313x.7781069.0.i33"
                              width="200"
                              height="200"
                            >
                              <path
                                d="M129.6 648.17c-6-27.33-9.03-55.54-9.03-83.87 0-171.92 114.97-326.21 279.59-375.2l14.43 48.49C271.26 280.26 171.18 414.6 171.18 564.3c0 24.69 2.64 49.27 7.84 73.04l-49.42 10.83zM511.98 955.72c-106.05 0-205.34-41.72-279.59-117.48l36.17-35.41c64.6 65.97 151.08 102.29 243.42 102.29 89.74 0 174.47-34.57 238.51-97.37l35.45 36.14C712.35 916 615.06 955.72 511.98 955.72zM894.39 648.17l-49.42-10.84c5.21-23.77 7.84-48.34 7.84-73.04 0-149.7-100.08-284.04-243.42-326.7l14.43-48.49c164.62 48.99 279.59 203.28 279.59 375.2 0.01 28.33-3.02 56.55-9.02 83.87z"
                                p-id="6520"
                                data-spm-anchor-id="a313x.7781069.0.i31"
                                class=""
                                fill="#1296db"
                              ></path>
                              <path
                                d="M511.92 328.17c-18.18 0-36.3-3.87-53.37-11.58-65.26-29.48-94.42-106.57-64.97-171.85 29.48-65.28 106.54-94.43 171.87-64.97 31.63 14.28 55.81 40.03 68.06 72.48 12.26 32.47 11.17 67.77-3.13 99.37-14.26 31.63-39.99 55.81-72.48 68.08-14.92 5.66-30.47 8.47-45.98 8.47z m0.23-209.29c-30.28 0-59.2 17.38-72.44 46.68-17.99 39.85-0.16 86.91 39.66 104.91 19.27 8.73 40.82 9.42 60.65 1.89 19.83-7.49 35.55-22.24 44.24-41.56 8.73-19.31 9.39-40.85 1.91-60.67s-22.24-35.53-41.54-44.24c-10.54-4.75-21.61-7.01-32.48-7.01zM194.87 877.28c-65.89 0-122.52-50.09-129.21-117-3.46-34.54 6.75-68.34 28.73-95.19 21.97-26.85 53.11-43.54 87.63-46.98 34.43-3.61 68.36 6.74 95.17 28.71 26.85 21.97 43.55 53.09 46.98 87.63 3.46 34.51-6.72 68.31-28.69 95.17-21.97 26.85-53.11 43.55-87.63 46.99-4.35 0.46-8.7 0.67-12.98 0.67z m0.19-209.23c-2.67 0-5.34 0.13-8.04 0.4-21.08 2.11-40.06 12.29-53.47 28.69-13.41 16.39-19.67 37.03-17.56 58.11 4.38 43.52 43.49 75.14 86.84 71.03 21.05-2.11 40.06-12.3 53.47-28.69 13.41-16.41 19.63-37.05 17.53-58.11-2.11-21.08-12.29-40.08-28.69-53.48-14.3-11.72-31.83-17.95-50.08-17.95zM829.23 877.21c-40.42 0-80.32-18.78-105.68-54.03v-0.02c-41.77-58.16-28.46-139.48 29.72-181.29 28.17-20.26 62.46-28.38 96.79-22.71 34.23 5.6 64.24 24.2 84.5 52.38 20.26 28.17 28.33 62.54 22.7 96.79-5.6 34.26-24.18 64.27-52.38 84.52-22.91 16.46-49.39 24.36-75.65 24.36z m-64.57-83.57c25.47 35.5 75.21 43.57 110.69 18.14 17.2-12.37 28.56-30.69 31.99-51.61 3.39-20.9-1.52-41.89-13.9-59.08-12.35-17.21-30.67-28.56-51.56-31.99-20.99-3.41-41.87 1.5-59.1 13.87-35.51 25.52-43.65 75.17-18.12 110.67z"
                                p-id="6521"
                                data-spm-anchor-id="a313x.7781069.0.i32"
                                class=""
                                fill="#1296db"
                              ></path>
                            </svg>
                          </div>
                          <div class="jieshao-item-text">
                            <p>技术支持</p>
                          </div>
                        </li>

                        <li class="jieshao-item">
                          <div class="hangkuai jieshao-item-icon">
                            <svg
                              t="1646275997625"
                              class="icon svg_temp"
                              viewBox="0 0 1251 1024"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              p-id="6161"
                              width="200"
                              height="200"
                            >
                              <path
                                d="M850.204444 475.591111a28.444444 28.444444 0 0 0 0-56.888889h-184.32l202.524445-166.684444a29.098667 29.098667 0 1 0-36.977778-44.942222l-212.764444 174.933333-212.764445-174.933333A28.444444 28.444444 0 1 0 369.777778 250.595556l202.524444 166.684444h-187.733333a28.444444 28.444444 0 0 0 0 56.888889h203.662222v87.324444h-203.662222a28.444444 28.444444 0 0 0 0 56.888889h203.662222V796.444444a28.444444 28.444444 0 1 0 56.888889 0v-175.502222h203.662222a28.444444 28.444444 0 0 0 0-56.888889h-202.24v-88.462222z"
                                p-id="6162"
                                fill="#1296db"
                              ></path>
                              <path
                                d="M1227.946667 371.768889l23.608889-3.982222V199.111111a199.111111 199.111111 0 0 0-199.111112-199.111111H199.111111a199.111111 199.111111 0 0 0-199.111111 199.111111v168.675556l23.608889 3.982222a142.222222 142.222222 0 0 1 0 280.462222l-23.608889 3.982222V824.888889a199.111111 199.111111 0 0 0 199.111111 199.111111h853.333333a199.111111 199.111111 0 0 0 199.111112-199.111111v-168.675556l-23.608889-3.982222a142.222222 142.222222 0 0 1 0-280.462222zM1052.444444 512a199.111111 199.111111 0 0 0 142.222223 190.862222V824.888889a142.222222 142.222222 0 0 1-142.222223 142.222222H199.111111a142.222222 142.222222 0 0 1-142.222222-142.222222v-122.026667a199.111111 199.111111 0 0 0 0-381.724444V199.111111a142.222222 142.222222 0 0 1 142.222222-142.222222h853.333333a142.222222 142.222222 0 0 1 142.222223 142.222222v122.026667A199.111111 199.111111 0 0 0 1052.444444 512z"
                                p-id="6163"
                                fill="#1296db"
                              ></path>
                            </svg>
                          </div>
                          <div class="jieshao-item-text">
                            <p>购买优惠</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 
            <div class="m-swiper-item swiper_back2">
              <div class="swiper_page2">
                <div class="swiper_title2">
                腾讯云域名专场特惠
              </div>
              <p class="swiper_p2">.cn 域名新用户仅需 8.8 元，更多热门域名限时特惠中</p>
              <a href="#" class="swiper_btn">了解详情</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="box-wrap ">
        
        <div class="box">
          <div class="box-header">
            <h2 class="box-title">
              <!-- <span class="hot_text">热门</span>  -->热门活动专区
            </h2>
            <div class="box-sub">
              <span class="box-sub_desc"
                >最新活动专区，您可了解腾讯云当前所有优惠活动</span
              >
              <span class="box-sub_links">
                <a href="https://cloud.tencent.com/act">更多活动</a>
              </span>
            </div>
          </div>
          <!-- 最新活动专区 -->
          <div class="new-box" id="active_bar">
            <ul class="new-list">
              <li class="new-item new-item_mright">
                <div class="new-item_1-back">
                  <img
                    style="width: 770px"
                    src="../assets/topactive.png"
                    alt=""
                  />
                </div>
                <a :href="topactive.href" class="new-item_1-btn"> 立即选购 </a>
              </li>

              <li
                class="new-item new-item-box"
                v-for="(item, index) in actives"
                v-bind:key="index"
              >
                <a :href="item.href" class="new-item_info hangkuai">
                  <div class="new-item_hd">
                    <h5 class="">{{ item.activename }}</h5>
                  </div>
                  <div class="new-item_body">
                    <p>
                      {{ item.desc }}
                    </p>
                  </div>
                  <div class="new-item_btn">立即选购 ></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="box-wrap wrap-back-goods">
        <div class="box">
          <!-- 标题 -->
          <div class="box-header">
            <h2 class="box-title">热门产品</h2>

            <div class="box-sub">
              <span class="box-sub_desc"
                >腾讯多年技术沉淀，300+ 款产品共筑腾讯云产品矩阵</span
              >
              <span class="box-sub_links">
                <a href="https://cloud.tencent.com/product">全部产品</a>
              </span>
            </div>
          </div>
          <!-- 内容 -->
          <div class="box-content">
            <!-- 热门产品列表 -->
            <div class="goodsList-left hangkuai">
              <div class="menu_hd">
                热门产品
                <img
                  src="../assets/fire-fill.png"
                  class="menu_hd_fire"
                  alt=""
                />
              </div>
              <div class="menu_1">
                <a
                  :href="item.href"
                  class="menu_1-item kuai"
                  v-for="(item, index) in hotgoods"
                  v-bind:key="index"
                >
                  <i class="menu_1-item_number">{{ index + 1 }}</i>
                  <h3 class="hangkuai menu_1-item_name pos">
                    {{ item.goodsname }}
                  </h3>
                  <div class="menu_1-item_desc pos">{{ item.shotdesc }}</div>
                </a>
              </div>
            </div>
            <!-- 产品列表 -->
            <div class="goodsList-right hangkuai">
              <div class="menu_hd">产品矩阵</div>
              <div class="menu_2">
                <ul>
                  <li
                    class="menu_2-item hangkuai"
                    v-for="(item, index) in goods"
                    v-bind:key="index"
                  >
                    <img
                      class="menu_2-item-icon"
                      src="https://qcloudimg.tencent-cloud.cn/image/product/2000/32_32/blue.svg"
                      height="40px"
                      width="40px"
                      alt=""
                    />
                    <a :href="item.href" class="kuai menu_2-item-body">
                      <div class="menu_2-item-hd">
                        <div class="menu_2-item-title">
                          {{ item.goodsname }}
                          <span
                            class="menu_2-item-title-mark"
                            v-if="item.discount != ''"
                            >{{ item.discount }}</span
                          >
                        </div>
                        <div class="menu_2-item-desc">
                          {{ item.shotdesc }}
                        </div>
                        <div class="menu_2-item-lables">
                          <span
                            class="menu_2-item-lable"
                            v-for="(tag, index2) in item.labels"
                            v-bind:key="index2"
                            >{{ tag }}</span
                          >
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div class="box-png">
            <img src="../assets/back.png" alt="" />
          </div> -->
        </div>
      </div>

      <!-- 解决方案推荐 -->
      <div class="box-wrap">
        <div class="solution_top">
          <h3 class="solu_top_title">腾讯云全栈解决方案</h3>
          <p class="solu_top_p">
            腾讯云面向丰富的业务场景，提供整套行业和技术解决方案，助力客户安全高效上云。
            <a
              href="https://cloud.tencent.com/solution"
              style="color: rgb(21, 113, 219)"
              >查看全部解决方案</a
            >
          </p>
        </div>
        <div class="solution_content">
          <div class="backImg_item">
            <div class="back_solu1">
              <div class="backImg_mask"></div>
            </div>
            <div class="backImg_info">
              <h3 class="backImg_title">互动直播解决方案</h3>
              <p class="backImg_p">
                互动直播解决方案，覆盖 PK
                连麦直播、派对直播、视频相亲、在线自习室、互动课堂等多种场景。
              </p>
              <a
                href="https://cloud.tencent.com/solution/ilvb"
                class="swiper_btn"
                style="color: "
                >了解详情</a
              >
            </div>
          </div>
          <div class="backImg_item">
            <div class="back_solu2">
              <div class="backImg_mask"></div>
            </div>
            <div class="backImg_info">
              <h3 class="backImg_title">在线课堂解决方案</h3>
              <p class="backImg_p">
                基于腾讯多年的音视频技术积累，提供在线直播、点播、互动多场景
                SaaS 在线课堂方案，帮助客户快速搭建自己的在线课堂平台。
              </p>
              <a
                href="https://cloud.tencent.com/solution/oics"
                class="swiper_btn"
                style="color: "
                >了解详情</a
              >
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="box-wrap wrap-back-2">
        <div class="box box-mask">
          <div class="box-header">
            <h2 class="box-title">云计算优势</h2>

            <div class="box-sub">
              <span class="box-sub_desc"
                >腾讯多年技术沉淀，300+ 款产品共筑腾讯云产品矩阵</span
              >
              <span class="box-sub_links">
                <a href="#">全部产品</a>
              </span>
            </div>
          </div>
          <div class="box-content">
            <div class="advantage-banner">
              <div class="banner-left hangkuai">
                <div class="banner-tit">
                  <h3 class="banner-title">弹性计算</h3>
                  <p class="banner-entitle">Elastic calculation</p>
                </div>
              </div>
              <div class="banner-center hangkuai">
                <img src="../assets/elastic.png" alt="" />
              </div>
              <div class="banner-right hangkuai">
                <p class="banner-dexc">
                  在腾讯云上您可以在几分钟之内快速增加或删减CVM数量，
                  以满足快速变化的业务需求。通过定义相关策略，
                  您可以确保所使用的 CVM 实例数量在需求高峰期无缝扩展，
                  保证程序的可用性。在需求平淡期自动回落，以节省成本。
                </p>
              </div>
            </div>

            <div class="advantage-banner">
              <div class="banner-left hangkuai">
                <p class="banner-dexc">
                  在腾讯云上您可以在几分钟之内快速增加或删减CVM数量，
                  以满足快速变化的业务需求。通过定义相关策略，
                  您可以确保所使用的 CVM 实例数量在需求高峰期无缝扩展，
                  保证程序的可用性。在需求平淡期自动回落，以节省成本。
                </p>
              </div>
              <div class="banner-center hangkuai">
                <img src="../assets/elastic.png" alt="" />
              </div>
              <div class="banner-right hangkuai">
                <h3 class="banner-title">弹性计算</h3>
                <p class="banner-entitle">Elastic calculation</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- 全球节点 -->
      <div class="box-wrap">
        <div class="global_top">
          <div class="global_title">腾讯云全球基础设施</div>
          <p>
            已开放 27 个地理区域，运营 70
            个可用区，为更多企业提供强有力的技术支持，助力业务飞速拓展。
          </p>
        </div>
        <div class="global_content">
          <div class="global_area">
            <img src="../assets/area-img.png" alt="" />
          </div>
          <div class="global_area" style="padding-bottom: 50px">
            <img src="../assets/area-img1.png" alt="" />
          </div>
        </div>
      </div>

      <div class="box-wrap wrap-back wrap-back-company">
        <div class="box">
          <div class="box-header">
            <h2 class="box-title">合作伙伴</h2>

            <div class="box-sub">
              <span class="box-sub_desc">COOPERATIVE PARTNER</span>
              <a
                href="https://partner.cloud.tencent.com/index?from=menu"
                style="color: blue"
              >
                ——more</a
              >
            </div>
          </div>
          <div class="box-centent">
            <div class="company-box">
              <div class="company-hd">
                <div
                  class="compamy-hd-item"
                  v-for="(item, index) in partners"
                  v-bind:key="index"
                >
                  <div class="compamy-hd-item-logo">
                    <img :src="item.img" alt="" />
                  </div>
                  <div class="compamy-hd-item-info">
                    <div class="compamy-hd-item_desc">
                      {{ item.desc }}
                    </div>
                    <div class="compamy-hd-item_name">
                      ----{{ item.partner }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="company-list">
                <ul class="">
                  <li class="company-item">
                    <img
                      src="	https://www.wstianxia.com/skin/images/026.png"
                      alt=""
                    />
                  </li>

                  <li class="company-item">
                    <img
                      src="	https://www.wstianxia.com/skin/images/026.png"
                      alt=""
                    />
                  </li>

                  <li class="company-item">
                    <img
                      src="	https://www.wstianxia.com/skin/images/026.png"
                      alt=""
                    />
                  </li>

                  <li class="company-item">
                    <img
                      src="	https://www.wstianxia.com/skin/images/026.png"
                      alt=""
                    />
                  </li>

                  <li class="company-item">
                    <img
                      src="	https://www.wstianxia.com/skin/images/026.png"
                      alt=""
                    />
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- 框架 -->
      <!-- <div class="box-wrap">
        <div class="box">
          <div class="box-header">
            <h2 class="box-title">热门产品</h2>

            <div class="box-sub">
              <span class="box-sub_desc"
                >腾讯多年技术沉淀，300+ 款产品共筑腾讯云产品矩阵</span
              >
              <span class="box-sub_links">
                <a href="#">全部产品</a>
              </span>
            </div>
          </div>
          <div class="box-centent"></div>
        </div>
      </div> -->

      <!-- 框架 -->
      <div class="box-wrap">
        <div class="box">
          <div class="box-centent">
            <div class="hezuo-box">
              <div class="hezuo-left hangkuai">
                <h3 class="hezuo-title">90+腾讯云产品免费体验</h3>
                <p class="hezuo-desc">
                  根据我们的操作提示，只需要点击几次鼠标，即可创建产品。
                </p>
                <a class="hezuo-btn" href="https://cloud.tencent.com/act/free"
                  >马上体验</a
                >
              </div>
              <div class="hezuo-right hangkuai">
                <h3 class="hezuo-title">关联厦门神仙网络(腾讯云)授权服务中心</h3>
                <p class="hezuo-desc">
                  关联我们，给您提供专业的上云售前咨询，技术服务。
                </p>
                <a class="hezuo-btn" :href="companyInfo.Associated_link"
                  >马上关联</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 关联链接 -->
      <div class="m-fix back-relationlink" id="relationlink">
        <div class="m-content auto-1200">
          <div class="m-relation-link-close" @click="closeRalationLink()">
            <a>
              <span
                class="layui-icon layui-icon-close"
                style="font-size: 16px; color: white"
              >
              </span>
            </a>
          </div>
          <div class="m-relation-link text-center">
            关联<span style="font-size: 20px; font-weight: 800">
              厦门神仙网络</span
            >，享受腾讯云产品购买售前服务和售后技术支持，点击关联链接进行关联
            <a :href="companyInfo.Associated_link" class="ralation-link"
              >关联神仙网络</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from "jquery";
export default {
  name: "Index",
  data() {
    return {
      swipers: require("../../static/json/swiperData.json"),
      topactive: {
        activename: "2022新春采购",
        img: "../assets/topactive.png",
        href: "https://cloud.tencent.com/act/2022season?from=16224",
        desc: "2022新春采购",
      },
      actives: require("../../static/json/activeData.json"),
      hotgoods: require("../../static/json/hotgoodsData.json"),
      goods: require("../../static/json/goodsData.json"),
      solutions: require("../../static/json/solutionsData.json"),
      partners: require("../../static/json/partnerData.json"),
      companyInfo: this.$store.state.companyInfo,
    };
  },
  methods: {
    
  },
  create: function () {},
  mounted: function () {
    // 走马灯js
    window.layui.use("carousel", function () {
      var carousel = window.layui.carousel;
      //建造实例
      carousel.render({
        elem: "#test1",
        width: "100%", //设置容器宽度
        height: "500px",
        arrow: "always", //始终显示箭头
        interval: "3000",
        autoplay: false,
        anim: "fade", //切换动画方式
        // full:true
      });
    });

    

  },
};
</script>

<style scoped>
@import url("../assets/css/public.css");

.m-wrap {
  width: 100%;
  min-width: 1200px;
  height: 130px;
  background: turquoise;
}

.m-swiper {
  width: 100%;
  min-width: 1200px;
  position: relative;
}

.m-swiper-item {
  width: 100%;
  /* background-image: url("../assets/slide1.png"); */
}

.swiper_back1 {
  background-size: 100%;
  background: cornflowerblue;
  background-image: url("../assets/red-newyear.jpg");
}
.swiper_back2 {
  background-size: 100%;
  background-position-y: -650px;
  background-image: url("../assets/blue-newyear.jpg");
}
.swiper_back3 {
  background-size: 100%;
  background: cornflowerblue;
  background-image: url("../assets/red-newyear.jpg");
  background-position-y: -800px;
}
.swiper_page1 {
  text-align: center;
}

.swiper_page2 {
  text-align: left;
  width: 1200px;
  margin: 0 auto;
}

.swiper_title1 {
  color: #fff;
  font-size: 60px;
  padding-top: 200px;
}
.swiper_p1 {
  color: #9ad0ff;
  font-size: 20px;
}
.swiper_title2 {
  color: #fff;
  font-size: 40px;
  padding-top: 200px;
}
.swiper_p2 {
  color: #9ad0ff;
  font-size: 20px;
}
.swiper_btn:hover {
  color: rgb(21, 113, 219);
  background: white;
}

.swiper_btn {
  margin-top: 20px;
  padding: 12px 40px;
  border: 1px solid white;
  color: white;
  display: inline-block;
}

.m-fix {
  width: 100%;
  padding: 18px 0;
  position: fixed;
  bottom: 0;
  /* opacity: 0.9; */
}

.ralation-link {
  margin-left: 30px;
  padding: 10px 25px;
  border: 1px solid white;
  color: white;
}

.m-relation-link-close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}
.wrap-back-company {
  background-image: url(https://cloudcache.tencent-cloud.com/open_proj/proj_qcloud_v2/portal-set/build/About/images/bg-mod-choose.jpg);

  background-size: 100% 100%;
}

.wrap-back-goods {
  background-image: url(https://cloudcache.tencent-cloud.com/open_proj/proj_qcloud_v2/portal-set/build/About/images/bg-mod-service.jpg);
  background-size: 100% 100%;
}
.wrap-back-2 {
  background-image: url(../assets/26524.jpg);

  background-size: 100%;
}
.box-wrap {
  min-width: 1200px;
  position: relative;
}
.box {
  margin: 0 auto;
  padding: 40px 0px;
  width: 1200px;
  position: relative;
}
.box-png {
  position: absolute;
  right: 0;
  bottom: -44px;
}
.box-header {
  text-align: center;
  margin-bottom: 40px;
}
.box-title {
  font-weight: 500;
  font-size: 28px;
  color: #000;
  line-height: 48px;
  position: relative;
}
.box-title::after {
  content: "";
  position: absolute;
  left: 550px;
  bottom: -6px;
  height: 6px;
  width: 100px;
  background-color: white;
}
.box-sub {
  margin-top: 12px;
}
.box-sub_desc {
  font-size: 16px;
  color: #3d485d;
  line-height: 20px;
}
.box-sub_links {
  margin-left: 12px;
}

.wrap-back-4 {
  background-image: url("https://sams-home-online-1302115363.file.myqcloud.com/static/background1.415231c6.png");
  background-size: 100%;
  background-position-y: 0;
}
.wrap-back-5 {
  background-color: #81bcf7;
  background-image: url("../assets/globalnode.png");
  background-size: 100%;
  background-position-y: 0;
}
.wrap-back-3 {
  background-color: #242933;
}
/* 公司介绍 */
.jieshao-box {
  border-radius: 8px;
  padding: 30px 30px;
  transition: all 2s;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);

  background: #fff;
}
/* .jieshao-box:hover {
  transition: all 0.5s;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(102 177 255 / 30%);
} */
.jieshao-list {
  text-align: center;
}
.jieshao-item {
  width: 15%;
  /* height: 100px; */
  display: inline-block;
  padding: 10px 56px;
  border-right: 1px solid #f3f5f8;
}
.jieshao-item:last-child {
  border-right: 0;
}
.jieshao-item-icon {
  vertical-align: middle;
}
.jieshao-item-icon img {
  width: 80px;
  height: 80px;
}
.jieshao-item-text {
  vertical-align: middle;
  font-size: 20px;
  color: #333;
  font-family: number-regular;
  margin-top: 10px;
}

/* 产品矩阵 */

.goodsList-left {
  vertical-align: top;
  width: 20%;
}

.menu_hd {
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d6dbe3;
  font-weight: 500;
  font-size: 14px;
  color: #0052d9;
  line-height: 22px;
}
.menu_hd_fire {
  width: 20px;
  height: 20px;
}
.menu_1-item:hover {
  border: 2px solid #0052d9;
}
.menu_1-item {
  margin-bottom: 13px;
  position: relative;
  padding: 12px 12px 12px 25px;
  cursor: pointer;
  height: 44px;
  background-image: linear-gradient(0deg, #ffffff 0%, #f3f5f8 100%);
  border: 2px solid #fff;
}
.menu_1-item:hover .menu_1-item_number {
  color: #f64041;
}
.menu_1-item_number {
  position: absolute;
  top: 14px;
  left: 14px;
  font-weight: 900;
  font-size: 30px;
  color: blue;
}

.pos {
  position: relative;
  top: 4px;
  left: 40px;
}

.menu_1-item_name {
  font-weight: normal;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu_1-item_desc {
  font-size: 12px;
  color: #495770;
  line-height: 20px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goodsList-right {
  vertical-align: top;
  padding-left: 20px;
  width: 75.5%;
}
.menu_2-item {
  vertical-align: top;
  padding: 0px 5px 30px 5px;
  position: relative;
}

.menu_2-item-icon {
  width: 40px;
  height: 40px;
  background: #f3f5f8;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 90px;
  z-index: 100;
  border: 4px solid #f3f5f8;
  display: none;
}

.menu_2-item-body:hover {
  border: 2px solid rgb(12, 110, 190);
}
.menu_2-item-body {
  width: 180px;
  position: relative;
  top: 20px;
  border: 2px solid #f3f5f8;
  padding: 10px 16px 10px 16px;
  /* background-image: url(https://cloudcache.tencent-cloud.com/open_proj/proj_qcloud_v2/tea-portal-material-portal/images/recom-bg5e7.jpg); */
  background-size: 100%;
  height: 90px;
}
.menu_2-item-hd {
  align-items: center;
  margin-bottom: 8px;
}
.menu_2-item-title {
  font-size: 16px;
  color: #000;
  line-height: 30px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu_2-item-title-mark {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 8px;
  height: 22px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  background-color: #f64041;
  border-radius: 2px;
  border-bottom-left-radius: 0;
}
.menu_2-item-desc {
  margin-bottom: 8px;
  font-size: 14px;
  color: #3d485d;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu_2-item-lables {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu_2-item-lable {
  color: #98a3b7;
  border: 1px solid rgba(157, 173, 193, 0.5);
  border-radius: 2px;
  margin-right: 8px;
  margin-bottom: 8px;
}

/* 活动专区 */
.new-box {
  margin: 0 auto;
  width: 1200px;
}
.new-list {
  position: relative;
}
.new-item {
  vertical-align: top;
  display: inline-block;
  position: relative;
  margin-bottom: 24px;
  margin-right: 24px;
}
.new-item_mright {
}
.new-item-box:hover {
  border: 1px solid #00a4ff;
}
.new-item-box {
  width: 370px;
  border: 1px solid #e5e8ed;
  height: 178px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(178 178 178 / 20%);
}
.new-item_1-back:hover img {
  transform: scale(1.1);
}
.new-item_1-back img {
  transition: all 1s;
}
.new-item_1-back {
  width: 770px;
  height: 180px;
  overflow: hidden;
}
.new-item_1-btn {
  padding: 6px 10px;
  position: absolute;
  top: 135px;
  left: 30px;
  color: #bd9f6c;
  border: 1px solid #bd9f6c;
}
.new-item_info {
  padding: 16px 20px;
  width: 322px;
}
.new-item_hd h5 {
  white-space: nowrap;
  padding-bottom: 4px;
  margin-bottom: 4px;
  border-bottom: 1px solid #eaedf4;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.new-item_body {
  font-size: 14px;
  color: #666;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.new-item_btn {
  position: relative;
  top: 20px;
  left: 0px;
  color: #bd9f6c;
}
/* 云计算优势 */
.box-content {
}
.box-mask {
  background: rgba(36, 1, 53, 0.384);
  background: rgba(2, 152, 252, 0.897);
}
.advantage-banner {
  padding: 40px 40px;
}
.banner-left {
  vertical-align: middle;
  text-align: right;
  width: 40%;
  /* height: 200px; */
  /* background: rgba(64, 224, 208, 0.582); */
}
.banner-title {
  color: #fff;
  font-size: 45px;
  border-bottom: 1px solid #9ad0ff;
  padding: 4px 0;
}
.banner-entitle {
  color: #9ad0ff;
  font-weight: 900;
  overflow: hidden;
  text-align: center;
  padding: 4px 0;
}
.banner-center {
  vertical-align: middle;
  width: 20%;
  overflow: hidden;
  text-align: center;
}
.banner-right {
  vertical-align: middle;
  width: 40%;
  overflow: hidden;
}
.banner-dexc {
  display: inline-block;
  text-align: left;
  color: #fff;
  width: 90%;
}

/* 合作伙伴 */
.company-box {
}
.company-hd {
  padding: 0 0 30px 0;
  text-align: center;
}
.compamy-hd-item {
  margin: 0 20px;
  display: inline-block;
  width: 500px;
  padding: 20px 20px;
  background: white;
  overflow: hidden;
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
}
.compamy-hd-item-logo {
  width: 80px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
}
.compamy-hd-item-info {
  text-align: left;
  vertical-align: top;
  display: inline-block;
  padding: 0 25px;
}
.compamy-hd-item_desc {
  width: 370px;
  height: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.compamy-hd-item_name {
  text-align: right;
  padding: 6px 0;

  font-size: 16px;
  color: #293957;
  line-height: 28px;
}
.company-list {
  text-align: center;
}
.company-item,
.compamy-hd-item:hover {
  box-shadow: 0 0 20px 0 rgb(102 177 255 / 30%);
}
.company-item {
  display: inline-block;
  width: 220px;
  height: 96px;
  overflow: hidden;
  margin: 10px 10px;
  box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
}
.company-item img {
  width: 220px;
  height: 96px;
}
/* 合作 */
.hezuo-box {
}
.hezuo-left {
  padding: 20px 30px;
}
.hezuo-right {
  /* width: 600px; */
  padding: 20px 30px;
}
.hezuo-title {
  color: #333;
  font-size: 28px;
}
.hezuo-desc {
  color: #666;
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 48px;
}
.hezuo-btn {
  padding: 10px 10px;
  color: #fff;
  background-color: #1ab9eb;
  border-color: #1ab9eb;
}

/* 全球节点 */
.infrastructure-item {
  display: inline-block;
  vertical-align: top;
  height: 268px;
}
.infrastructure-item_region-all {
  width: 200px;
  vertical-align: top;
}
.infrastructure-item_region-hd h3 {
  padding: 10px 20px 0;
  font-size: 14px;
  color: #3d485d;
  line-height: 28px;
  font-weight: 500;
}
.infrastructure-item_region-item {
  display: inline-block;
  padding: 10px 0 0 20px;
}
.infrastructure-item_region-list {
}
.infrastructure-item_region-item-label {
}
.infrastructure-item_region-item-label::after {
  content: "：";
}

.infrastructure-item_region-map {
  vertical-align: middle;
  width: 400px;
  height: 268px;
  overflow: hidden;
  line-height: 268px;
}
.infrastructure-item_region-map img {
  width: 400px;
  height: 200px;
}
/* 关联链接 */

.back-relationlink {
  background: #034da7;
  color: white;
}

.svg_temp {
  width: 80px;
  height: 80px;
}

.global_top {
  padding-bottom: 20px;
  text-align: center;
  background: #27292b;
  /* background-image: url(https://imgcache.qq.com/open_proj/proj_qcloud_v2/gateway/global-base/css/img/new/banner.jpg); */
}
.global_title {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
  margin-top: 40px;
  display: inline-block;
  margin-bottom: 18px;
}
.global_top > p {
  color: #999;
  font-size: 16px;
}

.global_content {
  background: #27292b;
}
.global_area {
  padding: 20px 0;
  text-align: center;
}
.hot_text {
  color: #f64041;
  font-weight: 900;
}

/* 解决方案 */
.solution_top {
  width: 100%;
  height: 80px;
  /* background: rgba(187, 185, 165, 0.356); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  text-align: center;
  padding: 30px 0;
}
.solu_top_title {
  color: #fff;
  font-size: 40px;
  font-weight: 900;
}
.solu_top_p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}
.solution_content {
  width: 100%;
  height: 650px;
  position: relative;
}

.backImg_item {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.back_solu1:hover {
  transform: scale(1.1, 1.1);
}
.back_solu1 {
  width: 100%;
  height: 100%;
  background-image: url("../assets/Webcast.png");
  background-size: auto 100%;
  background-position-x: -300px;
  transition: all 1s;
}
.back_solu2:hover > .backImg_mask {
}
.back_solu2:hover {
  transform: scale(1.1, 1.1);
}
.back_solu2 {
  width: 100%;
  height: 100%;
  background-image: url("../assets/onlineEducation.jpg");
  background-size: auto 100%;
  background-position-x: -100px;
  transition: all 1s;
}
.backImg_mask:hover {
  background: rgba(121, 109, 109, 0.521);
}
.backImg_mask {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.backImg_info {
  width: 300px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 4px solid white;
  padding: 10px 10px;
}
.backImg_title {
  color: white;
  font-weight: 900;
  font-size: 30px;
}
.backImg_p {
  color: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}


/* 滚动动画 */
@keyframes key {
  0% {
    margin-left: -50px;
    opacity: 0;
    background:red;
  }
  50% {
    margin-left: 50px;
    opacity: 0.5;background:rgb(78, 30, 30);
  }
  100% {
    margin-left: 0;
    opacity: 1;
  }
}
</style>
